import React, { useState, useEffect, useContext, useRef } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
// components
import { useAuthContext } from "../hooks/useAuthContext"
import usePage from "../hooks/usePage"
import useUser from "../hooks/useUser"
import Widgets from "../helpers/widgets"
import { useDocument } from "../hooks/useDocument"
import { StateContext } from "../helpers/StateProvider"
import ExportPdfComponent from "../components/exportButton/ExportPdfComponent"
import ReactToPrint from "react-to-print"
import { Share } from "../components/LisitngDetails/ListingDetails.styles"

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share"
import Faceboook from "../assets/icons/facebook-share.svg"
import Twitter from "../assets/icons/twitter-share.svg"
import Linkedin from "../assets/icons/linkedin-share.svg"
import Whatsapp from "../assets/icons/whatsapp-icon.svg"
import telegram from "../assets/icons/telegram_logo_circle_icon.svg"
import email from "../assets/icons/email-envelop.svg"

const IndexPage = props => {
  const { user, authIsReady } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [pageWidgets, setPageWidgets] = usePage("home")
  const [arabic, setArabic] = useState(false)
  const componentRef = useRef()
  const currentUrl = window.location.href
  useEffect(() => {
    setLoading(false)
  }, [pageWidgets])
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {authIsReady && (
        <>
          <>
            <Seo title="property-restoration" />
            <div
              ref={componentRef}
              className={
                language == false
                  ? "container cards_about-us"
                  : "text-right container cards_about-us"
              }
            >
              <h4>
                {language == false ? "Dear  customer," : "عزيزنا العميل،"}
              </h4>
              <p>
                {language == false
                  ? "Our team is always ready to visit your property free of charge and ask you about your requirements and demands. "
                  : "فريقنا جاهز دائمًا لزيارة مكان الإقامة الخاص بك مجانًا وسؤالك عن متطلباتك ومطالبك."}
              </p>
              <p>
                {language == false
                  ? "However, kindly ask for an appointment,"
                  : "ومع ذلك ، يرجى طلب موعد ،"}
              </p>
              <p>
                {language == false
                  ? "Just call +961 3 948 739 daily between 09:00 AM and 05:00 PM except on the weekend (Saturday and Sunday)"
                  : "ما عليك سوى الاتصال على الرقم + 961 3948739 يوميًا بين الساعة 09:00 صباحًا و 05:00 مساءً باستثناء عطلة نهاية الأسبوع (السبت والأحد)"}
              </p>
              <p>{language == false ? "Regards" : "مع تحياتي"}</p>
              <p>
                {language == false
                  ? "Here are some samples of our work:"
                  : "فيما يلي بعض نماذج عملنا:"}
              </p>
              <a href="#work">
                {language == false
                  ? "Kindly check our home decoration samples"
                  : "يرجى التحقق من عينات الديكور المنزلي لدينا"}
              </a>
              <br />
              <a href="#wood">
                {language == false
                  ? "Kindly check our wood decorarion samples"
                  : "يرجى التحقق من عينات الديكور الخشبية لدينا"}
              </a>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <h6 id="work">{language == false ? "Before" : "قبل"}</h6>
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2FPicture1.png?alt=media&token=a53effef-da41-4f81-bed6-19ae0df23478"></img>
                </div>
                <div className="d-flex flex-column ">
                  <h6>{language == false ? "After" : "بعد"}</h6>
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2FPicture2.png?alt=media&token=34dbff31-8113-4142-863b-15cdbcd8e386"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <h6>{language == false ? "Before" : "قبل"}</h6>
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2FPicture3.png?alt=media&token=2ba5e4c2-0c8b-47b5-a5ee-bc70d8703ce1"></img>
                </div>
                <div className="d-flex flex-column ">
                  <h6>{language == false ? "After" : "بعد"}</h6>
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2FPicture4.png?alt=media&token=f3e5cf59-f6d0-4559-bb31-9f972579277b"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <h6>{language == false ? "Before" : "قبل"}</h6>
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2FPicture5.png?alt=media&token=17342997-03b5-458f-ac81-97e6722d886e"></img>
                </div>
                <div className="d-flex flex-column ">
                  <h6>{language == false ? "After" : "بعد"}</h6>
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2FPicture6.png?alt=media&token=c490502c-3693-4c1e-9832-3bd8df39a7eb"></img>
                </div>
              </div>
              <h4 id="wood">
                {language == false
                  ? "Wood Decorarion Samples"
                  : "عينات ديكور الخشب"}
              </h4>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fwood10.png?alt=media&token=99aeb770-4c9d-4705-a763-2ad3c8387555"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fwood9.png?alt=media&token=360d0705-2a1a-4275-9591-b9d30a15aae3"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fwood8.png?alt=media&token=e65b2f79-0386-44ba-9311-6a2cb659a03b"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fwood7.png?alt=media&token=2095ae32-cb58-414a-92a4-46adf7da9639"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fwood6.png?alt=media&token=de871dc6-3e2b-4802-b18d-d46623ce4753"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fwood5.png?alt=media&token=746bb7e8-2f22-4556-bfe4-f6edc65e3c3a"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse4.png?alt=media&token=6cd9eadc-68ff-46b5-bcd2-4880f7993e0f"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fwood3.png?alt=media&token=ea84464b-03a5-43c8-b9c9-8e3ba5e42eea"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fwood2.png?alt=media&token=d709bab6-6db2-4b84-86e1-e5271e2f6610"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fwood1.png?alt=media&token=2a2f3d77-240d-4d5c-8172-a813c4213e94"></img>
                </div>
              </div>
              <Share>
                <div className="text">
                  {arabic == false ? "Share on" : "مشاركه فى"}
                </div>
                <div className="socials">
                  <FacebookShareButton
                    url={currentUrl}
                    title="Facebook"
                    hashtag="#property"
                  >
                    <img src={Faceboook} alt="facebook icon" />
                  </FacebookShareButton>
                  <TwitterShareButton url={currentUrl} title="Twitter">
                    <img src={Twitter} alt="twitter icon" />
                  </TwitterShareButton>
                  <LinkedinShareButton url={currentUrl} title="Linkedin">
                    <img src={Linkedin} alt="linkedin icon" />
                  </LinkedinShareButton>
                  <WhatsappShareButton url={currentUrl} title="Whatsapp">
                    <img src={Whatsapp} alt="whatsapp icon" />
                  </WhatsappShareButton>
                  <TelegramShareButton url={currentUrl} title="telegram">
                    <img
                      src={telegram}
                      alt="whatsapp icon"
                      style={{ width: "64px" }}
                    />
                  </TelegramShareButton>
                  <EmailShareButton
                    url={currentUrl}
                    subject="share page"
                    body={"this is link shared"}
                  >
                    <img
                      src={email}
                      alt="whatsapp icon"
                      style={{ width: "50px" }}
                    />
                  </EmailShareButton>
                </div>
              </Share>
            </div>

            <ReactToPrint
              content={() => componentRef.current}
              trigger={() => (
                <button className="btn btn-primary">Print to PDF!</button>
              )}
            />
          </>
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allNews(limit: 3, sort: { fields: created_at }) {
      edges {
        node {
          description
          featured_image
          id
          overview
          title
          topic_title
          topic
          created_at
          localImage {
            childImageSharp {
              fixed(width: 300, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allEvents(limit: 2, sort: { fields: date }) {
      edges {
        node {
          date
          city
          address
          name
          id
          country
          thumbnail {
            url
          }
        }
      }
    }
    allPage(filter: { name: { eq: "home" } }) {
      edges {
        node {
          name
          title
          id
          description
          widgets {
            name
            type
          }
        }
      }
    }
    allHero {
      edges {
        node {
          image {
            url
          }
          localImage {
            childImageSharp {
              fixed(width: 2000, height: 800) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          id
          title
          title_ar
          paragraph
          paragraph_ar
          tagline
          tagline_ar
          link {
            text_ar
            text
            url
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget: allWidget {
      edges {
        node {
          description
          id
          image

          link_text
          link
          name
          title
          type
        }
      }
    }
    widget1: widget(name: { eq: "how-we-works" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
    }
    widget2: widget(name: { eq: "property-type" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget3: widget(name: { eq: "explore-properties" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget4: widget(name: { eq: "our-latest-news" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget5: widget(name: { eq: "it-is-time-to-expect-more" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget6: widget(name: { eq: "our-latest-events" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default IndexPage
